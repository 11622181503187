import React, { Suspense, lazy } from 'react';

const FooterGroup = lazy(() => import(/* webpackChunkName: "FooterGroup" */ 'Layout/Footer/FooterGroup'));

function FooterGroupContainer() {
  return (
    <Suspense fallback={<div><div id="footer-breadcrumbs" /></div>}>
      <FooterGroup />
    </Suspense>
  );
}

export default FooterGroupContainer;
